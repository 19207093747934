import { BrickElement, defineCustomElement } from '@amedia/brick-template';
import type { BrickTeaserReelsData } from './types';
import { brickTeaserReelsTemplate } from './template';
import '@amedia/brick-image';

@defineCustomElement({
  selector: 'brick-teaser-reels',
})
export class BrickTeaserReels
  extends BrickElement
  implements BrickTeaserReelsData
{
  dataTitle?: string;
  dataImageSrc?: string;
  dataVideoSrc?: string;
  dataUrl?: string;
  dataAspectRatio?: string;

  // Instance properties to store element references and listeners.
  #reelsTeaser: HTMLElement | null = null;
  #video: HTMLVideoElement | null = null;
  #startVideo: (() => void) | null = null;
  #stopVideo: (() => void) | null = null;
  #handleClick: ((e: Event) => void) | null = null;
  #mobileTimeout: number | null = null;
  #intersectionObserver?: IntersectionObserver;

  constructor() {
    super();
  }

  // All attributes are strings. They are converted to properties via mirroredProps.
  static get mirroredProps(): string[] {
    return [
      'data-title',
      'data-image-src',
      'data-video-src',
      'data-url',
      'data-aspect-ratio',
    ];
  }

  async connectedCallback(): Promise<void> {
    super.connectedCallback();

    // Get the required elements.
    this.#reelsTeaser = this.querySelector('.reels-teaser') as HTMLElement;
    this.#video = this.#reelsTeaser?.querySelector(
      '.teaser-video'
    ) as HTMLVideoElement;

    if (!this.#reelsTeaser || !this.#video) {
      console.error('Required elements not found');
      return;
    }

    // Define the functions to start and stop playback.
    this.#startVideo = () => {
      if (this.#reelsTeaser) {
        this.#reelsTeaser.classList.add('is-hovered');
      }
      if (this.#video) {
        this.#video.currentTime = 0; // Restart the video
        this.#video.play().catch((error: Error) => {
          if (error.name !== 'AbortError') {
            console.error('Video playback error:', error);
          }
        });
      }
    };

    this.#stopVideo = () => {
      if (this.#reelsTeaser) {
        this.#reelsTeaser.classList.remove('is-hovered');
      }
      this.#video?.pause();
    };

    this.#handleClick = (clickEvent) => {
      this.dispatchEvent(
        new CustomEvent('amedia:popcorn:video-teaser:click', {
          bubbles: true,
          detail: { clickEvent, type: 'reels' },
        })
      );
    };

    // Attach event listeners using the bound functions.
    this.#reelsTeaser.addEventListener('pointerenter', this.#startVideo);
    this.#reelsTeaser.addEventListener('pointerleave', this.#stopVideo);
    this.#reelsTeaser.addEventListener('focus', this.#startVideo);
    this.#reelsTeaser.addEventListener('blur', this.#stopVideo);
    this.#reelsTeaser.addEventListener('click', this.#handleClick);

    // Mobile behavior: Use an IntersectionObserver for touch devices.
    const isMobile = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    if (isMobile) {
      this.#intersectionObserver = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              if (this.#mobileTimeout) {
                clearTimeout(this.#mobileTimeout);
              }
              this.#startVideo?.();
              // Stop video after 5 seconds to comply with guidelines.
              this.#mobileTimeout = window.setTimeout(() => {
                this.#stopVideo?.();
              }, 5000);
            } else {
              if (this.#mobileTimeout) {
                clearTimeout(this.#mobileTimeout);
                this.#mobileTimeout = null;
              }
            }
          });
        },
        { threshold: 0.5 }
      );
      this.#intersectionObserver.observe(this.#reelsTeaser);
    }
  }

  async disconnectedCallback(): Promise<void> {
    // Clean up all event listeners.
    if (
      this.#reelsTeaser &&
      this.#startVideo &&
      this.#stopVideo &&
      this.#handleClick
    ) {
      this.#reelsTeaser.removeEventListener('pointerenter', this.#startVideo);
      this.#reelsTeaser.removeEventListener('pointerleave', this.#stopVideo);
      this.#reelsTeaser.removeEventListener('focus', this.#startVideo);
      this.#reelsTeaser.removeEventListener('blur', this.#stopVideo);
      this.#reelsTeaser.removeEventListener('click', this.#handleClick);
    }
    // Disconnect the IntersectionObserver.
    if (this.#intersectionObserver) {
      this.#intersectionObserver.disconnect();
    }
    super.disconnectedCallback();
  }

  /*The HTML getter is used by brick-template to clone content from a <template> element. This is more performant than using innerHTML because it avoids additional HTML parse costs.*/
  get HTML() {
    return brickTeaserReelsTemplate({
      dataTitle: this.dataTitle || '',
      dataImageSrc: this.dataImageSrc || '',
      dataVideoSrc: this.dataVideoSrc || '',
      dataUrl: this.dataUrl || '',
      dataAspectRatio: this.dataAspectRatio || '',
    });
  }
}
