import { getCssText } from '@amedia/brick-tokens';
import { imageStyle, wrapperStyle } from './style.js';
import type { BrickImageTemplate } from './types';
let cachedCssText: string | null = null;

const validateString = (value) => {
  if (typeof value === 'string' && value === 'undefined') {
    return null;
  }
  return value;
};

const buildAttributes = (data: BrickImageTemplate): string => {
  const brickImageAttributes: string[] = [];

  if (data.dataSrc) {
    brickImageAttributes.push(`data-src="${data.dataSrc}"`);
  }
  if (data.dataSrcsize) {
    brickImageAttributes.push(`data-srcsize="${data.dataSrcsize}"`);
  }
  if (data.dataSizes) {
    brickImageAttributes.push(`data-sizes="${data.dataSizes}"`);
  }
  if (data.dataSrcset) {
    brickImageAttributes.push(`data-srcset="${data.dataSrcset}"`);
  }
  if (data.dataWidth && data.dataWidth !== 'undefined') {
    brickImageAttributes.push(`data-width="${data.dataWidth}"`);
  }
  if (data.dataHeight && data.dataHeight !== 'undefined') {
    brickImageAttributes.push(`data-height="${data.dataHeight}"`);
  }
  if (data.dataAltText) {
    brickImageAttributes.push(`data-alt-text="${data.dataAltText}"`);
  } else {
    brickImageAttributes.push(`data-alt-text=""`);
  }
  if (data.dataFetchPriority) {
    brickImageAttributes.push(
      `data-fetch-priority="${data.dataFetchPriority}"`
    );
  }
  if (data.dataLoading) {
    brickImageAttributes.push(`data-loading="${data.dataLoading}"`);
  }

  return brickImageAttributes.join(' ');
};

export function brickImageTemplate(data: BrickImageTemplate) {
  const {
    dataSrc = '',
    dataSrcset = '',
    dataAltText = '',
    dataSizes,
    dataFetchPriority = '',
    dataLoading = 'lazy',
    dataWidth = '',
    dataHeight = '',
  } = data;

  const ratio = `${validateString(dataWidth)} / ${validateString(dataHeight)}`;
  const ratioVar =
    validateString(dataWidth) && validateString(dataHeight)
      ? `--brick-image-ratio: ${ratio};`
      : '';

  const getAttribute = (name: string, value: string) =>
    value ? `${name}="${value}"` : '';

  const markup = `<img 
      ${getAttribute('width', validateString(dataWidth))}
      ${getAttribute('height', validateString(dataHeight))}
      ${getAttribute('fetchpriority', dataFetchPriority)}
      ${getAttribute('sizes', validateString(dataSizes))}
      ${getAttribute('loading', dataLoading)}
      ${getAttribute('srcset', validateString(dataSrcset))}
      ${getAttribute('src', dataSrc)}
      alt="${validateString(dataAltText) || ''}" 
      class="${imageStyle || ''}" 
      style="${ratioVar || ''}">`;

  if (data.isRendered) {
    const attributes = buildAttributes(data);
    return `<brick-image-v${data.version} is-rendered class="${wrapperStyle}" ${attributes}>${markup}</brick-image-v${data.version}>`;
  }
  return markup;
}

export function renderBrickImage(data: BrickImageTemplate) {
  data.isRendered = true;
  data.version = '4';
  return brickImageTemplate(data);
}

export function cssText() {
  if (cachedCssText === null) {
    imageStyle();
    cachedCssText = getCssText(); // Cache the result
  }
  return cachedCssText;
}
