import { getCssText, sroStyle } from '@amedia/brick-tokens';
import { renderBrickImage } from '@amedia/brick-image/template';

import { teaserReelsClass } from './styles.js';
import { BrickTeaserReelsTemplate } from './types';

export { getCssText };
export type { BrickTeaserReelsTemplate };

function brickImageSrc({ url, graphicsHost = 'g.acdn.no' }) {
  if (!url) {
    return;
  }

  const resizedUrl = `https://${graphicsHost}/api/reflex/v1/image/resize/680`;

  return `${resizedUrl}/${url.trim()}`;
}

export const brickTeaserReelsTemplate = (data: BrickTeaserReelsTemplate) => {
  const {
    dataTitle,
    dataImageSrc,
    dataVideoSrc,
    dataUrl,
    dataAspectRatio = '9/16',
  } = data;

  // Unique id for the title element to use for aria-labelledby
  const titleId = `titleId-${Math.random().toString(36).substring(2)}`;

  let image = '';
  if (dataImageSrc) {
    image = renderBrickImage({
      dataSrc: brickImageSrc({ url: dataImageSrc }) || dataImageSrc,
      dataWidth: '250',
      dataHeight: '445',
      dataSizes: '(min-width 600px) 250px, 100vw"',
      dataLoading: 'lazy',
    });
  }

  // Map video urls to source elements. Smallest first (first in the commaseparated list), largest last.
  let videoSources = '';
  if (dataVideoSrc) {
    const urls = dataVideoSrc.split(',').map((url) => url.trim());
    if (urls.length === 1) {
      videoSources = `<source src="${urls[0]}">`;
    } else {
      videoSources = urls
        .map((url, index) => {
          const media = index === 0 ? '(min-width: 600px)' : '';
          return `<source src="${url}"${media ? ` media="${media}"` : ''}>`;
        })
        .join('');
    }
  }

  const markup = `<a href="${dataUrl}" class="${teaserReelsClass} reels-teaser" aria-labelledby="${titleId}">
            <div class="media-container" style="aspect-ratio: ${dataAspectRatio}">
              ${image}
              <video class="teaser-video" loop muted playsinline aria-hidden="true">
                 ${videoSources}
              </video>
              <h3 id="${titleId}" class="teaser-title">
                <span class="${sroStyle}">Videoklipp-serie: </span>
                <span>${dataTitle}</span>
              </h3>
            </div>
          </a>`;
  // If isRendered ==='true' it means the markup is rendered serverside, so we do not want to update the DOM client side.
  if (data.isRendered) {
    return `<brick-teaser-reels 
      data-title="${dataTitle}" 
      data-url="${dataUrl}" 
      data-image-src="${dataImageSrc}"
      data-video-src="${dataVideoSrc}"
      data-aspect-ratio="${dataAspectRatio}"
      is-rendered >${markup}</brick-teaser-reels>`;
  }
  // If not, we return the markup that will be attached to the DOM client side, by our web component classs
  return `${markup}`;
};

export const renderBrickTeaserReels = (dataset: BrickTeaserReelsTemplate) => {
  const {
    dataTitle,
    isRendered = true,
    dataUrl,
    dataImageSrc,
    dataVideoSrc,
    dataAspectRatio,
  } = dataset;
  return `${brickTeaserReelsTemplate({
    dataTitle,
    dataUrl,
    dataImageSrc,
    dataVideoSrc,
    dataAspectRatio,
    isRendered,
  })}`;
};
