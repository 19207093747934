import { css } from '@amedia/brick-tokens';

export const teaserReelsClass = css({
  '--_b-reels-color-fg':
    'var(--b-reels-color-fg, var(--brick-colors-supportiveReelMarkerFg, #fff))',
  '--_b-reels-color-bg':
    'var(--b-reels-color-bg, var(--brick-colors-supportiveReelMarkerBg, #000))',
  '--_b-reels-radii': 'var(--b-reels-radii, var(--brick-radii-teaser, 6px))',

  display: 'block',
  cursor: 'pointer',
  position: 'relative',

  '& .media-container': {
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    borderRadius: 'var(--_b-reels-radii)',
  },
  '& .media-container img, & .media-container video': {
    display: 'block',
    width: '100%',
  },
  '& brick-image-v3': {
    position: 'relative',
    pointerEvents: 'none',
    opacity: 1,
    transition: 'opacity 0.3s ease-out',
  },
  '& .teaser-video': {
    position: 'absolute',
    inset: '0',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    opacity: 0,
    transition: 'opacity 0.3s ease-out',
  },
  '&:not(:has(img)) .teaser-video': {
    opacity: 1,
  },
  '& .teaser-title': {
    fontFamily: 'var(--brick-fonts-titleReelsMarkerM)',
    fontSize: 'var(--brick-fontSizes-titleReelsMarkerM)',
    fontWeight: 'var(--brick-fontWeights-titleReelsMarkerM)',
    position: 'absolute',
    bottom: '0',
    left: '0',
    color: 'var(--_b-reels-color-fg)',
    padding: '0',
    margin: 'var(--brick-space-teaserBoxInsetM)',
    textAlign: 'left',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '4',
    overflow: 'hidden',
    zIndex: '1',
  },
  '& .teaser-title span': {
    backgroundColor: 'var(--_b-reels-color-bg)',
    padding: '3px 6px',
    WebkitBoxDecorationBreak: 'clone',
    boxDecorationBreak: 'clone',
    lineHeight: 1.4,
  },
  '&:focus': {
    outline: '2px solid var(--utilityInteractionFocusOutline)',
    outlineOffset: '4px',
  },

  // When hovered, fade in the video and fade out the image.
  '&.is-hovered .teaser-video': {
    opacity: 1,
  },
  '&.is-hovered brick-image-v3': {
    opacity: 0,
  },
});
